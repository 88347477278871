import { Backdrop, Checkbox, Chip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormatAlignLeft from "@material-ui/core/SvgIcon/SvgIcon";
import Tooltip from "@material-ui/core/Tooltip";
import Favorite from "@material-ui/icons/Favorite";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LocalShipping from "@material-ui/icons/LocalShipping";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Header from "components/HeaderShop/Header.js";
import HeaderLinks from "components/HeaderShop/HeaderLinks.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Parallax from "components/Parallax/Parallax.js";
import I18n from "i18n-js";
/*eslint-disable*/
import React from "react";
import Currency from "react-currency-formatter";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

import { API_URL, SHOPS_CONFIG } from "../../utils/Constants";
import {
    getProductImageUrl,
    isEmpty,
    resetCache,
    searchInArray,
    useEndpoint,
    useLocalStorage,
    useSiteLanguage
} from "../../utils/Utils";

const useStyles = makeStyles(productStyle);

export default function ProductPage(props) {
    const executeScroll = () => window.scroll(0, 170);

    const [shoppingCartStore, setShoppingCartStore] = useLocalStorage(
        `shoppingCartStore-${props.match.params.shopId}`,
        []
    );
    const [shoppingCart, setShoppingCart] = React.useState(shoppingCartStore);
    const [siteLanguage, setSiteLanguage] = useSiteLanguage("siteLanguage", "nl");

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddProduct = value => {
        let newShoppingCart = shoppingCart;

        const index = newShoppingCart.findIndex(
            x =>
                x.description === value.description &&
                x.internal_product_id === value.internal_product_id &&
                x.kind_id === value.kind_id &&
                x.price === value.price
        );
        if (index === -1) {
            value.quantity = 1;
            newShoppingCart.push(value);
        } else {
            newShoppingCart[index].quantity += 1;
        }
        setShoppingCart(newShoppingCart);
        setShoppingCartStore(newShoppingCart);
        handleClose();
    };

    const closeModal = () => {
        handleClose();
    };

    const api = `${API_URL}/v1/shops/${props.match.params.shopId}`;
    const shop = useEndpoint({
        method: "GET",
        url: `${api}`
    });

    const api_kind = `${API_URL}/v1/kinds/${props.match.params.kindId}`;
    const kind = useEndpoint({
        method: "GET",
        url: `${api_kind}`
    });

    const chisLabels = { c: "CBD", h: "Hybrid", i: "Indica", s: "Sativa" };
    let chisLabel = "";
    if (kind.complete && kind.data.c === true) {
        chisLabel = chisLabels.c;
    }
    if (kind.complete && kind.data.h === true) {
        chisLabel = chisLabels.h;
    }
    if (kind.complete && kind.data.i === true) {
        chisLabel = chisLabels.i;
    }
    if (kind.complete && kind.data.s === true) {
        chisLabel = chisLabels.s;
    }

    const prices = !shop.complete
        ? undefined
        : shop.data.prices.filter(price => price.id === props.match.params.priceId)[0];

    const classes = useStyles();
    const images = [
        {
            original: getProductImageUrl(kind.complete ? kind.data.image_1 : "", 600),
            thumbnail: getProductImageUrl(kind.complete ? kind.data.image_1 : "")
        },
        {
            original: getProductImageUrl(kind.complete ? kind.data.image_2 : "", 600),
            thumbnail: getProductImageUrl(kind.complete ? kind.data.image_2 : "")
        },
        {
            original: getProductImageUrl(kind.complete ? kind.data.image_3 : "", 600),
            thumbnail: getProductImageUrl(kind.complete ? kind.data.image_3 : "")
        }
    ];

    React.useEffect(() => {
        executeScroll();
        return function cleanup() {};
    });

    return (
        <div className={classes.productPage}>
            <Header
                brand={shop.complete ? shop.data.name : ""}
                brandLink={`/shop/${props.match.params.shopId}?storage=1`}
                links={
                    <HeaderLinks
                        dropdownHoverColor="rose"
                        showShoppingCart={SHOPS_CONFIG[props.match.params.shopId].showShoppingCart}
                        shoppingCartItems={shoppingCart}
                        shopId={props.match.params.shopId}
                    />
                }
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 100,
                    color: "dark"
                }}
            />
            <Parallax
                image={require("assets/img/weed_banner_default1.jpg")}
                filter="dark"
                className={classes.pageHeader}
            ></Parallax>
            <div className={classNames(classes.section, classes.sectionGray)}>
                <div className={classes.container}>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <h4 id="transition-modal-title">{I18n.t("shoppingCartModal.selectQuantity")}</h4>
                                <div id="transition-modal-description">
                                    {prices && prices.half && (
                                        <div key={2}>
                                            <b>
                                                0,5 gram:{" "}
                                                <Currency quantity={prices.half} currency="EUR" locale="nl_NL" />
                                            </b>{" "}
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "0,5 gram",
                                                        price: prices.half,
                                                        kind_id: prices.kind_id,
                                                        kind_name: prices.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: prices.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {prices && prices.one && (
                                        <div key={2}>
                                            <b>
                                                1 gram: <Currency quantity={prices.one} currency="EUR" locale="nl_NL" />
                                            </b>{" "}
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "1 gram",
                                                        price: prices.one,
                                                        kind_id: prices.kind_id,
                                                        kind_name: prices.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: prices.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {prices && prices.two_five && (
                                        <div key={3}>
                                            <b>
                                                2.5 gram:{" "}
                                                <Currency quantity={prices.two_five} currency="EUR" locale="nl_NL" />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "2,5 gram",
                                                        price: prices.two_five,
                                                        kind_id: prices.kind_id,
                                                        kind_name: prices.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: prices.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {prices && prices.five && (
                                        <div key={4}>
                                            <b>
                                                5 gram:{" "}
                                                <Currency quantity={prices.five} currency="EUR" locale="nl_NL" />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "5 gram",
                                                        price: prices.five,
                                                        kind_id: prices.kind_id,
                                                        kind_name: prices.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: prices.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {prices && prices.joint && (
                                        <div key={5}>
                                            <b>
                                                1 joint:{" "}
                                                <Currency quantity={prices.joint} currency="EUR" locale="nl_NL" />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "1 joint",
                                                        price: prices.joint,
                                                        kind_id: prices.kind_id,
                                                        kind_name: prices.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: prices.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {prices && prices.piece && (
                                        <div key={6}>
                                            <b>
                                                1 item:{" "}
                                                <Currency quantity={prices.piece} currency="EUR" locale="nl_NL" />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "1",
                                                        price: prices.piece,
                                                        kind_id: prices.kind_id,
                                                        kind_name: prices.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: prices.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    <Button onClick={closeModal}>{I18n.t("shoppingCartModal.close")}</Button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        <div className={classes.space}>
                            <Link
                                to={`/shop/${props.match.params.shopId}/category/${props.match.params.categoryId}`}
                                activeClassName="current"
                            >
                                <KeyboardBackspaceIcon />
                            </Link>
                        </div>
                        <GridContainer key={113}>
                            <GridItem key={28092890} md={6} sm={6}>
                                <ImageGallery
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    startIndex={1}
                                    items={images}
                                />
                            </GridItem>
                            <GridItem key={209209} md={6} sm={6}>
                                <GridContainer>
                                    <GridItem key={24533} md={9} sm={12}>
                                        <h2 className={classes.title} onClick={resetCache}>
                                            {kind.complete ? kind.data.name : ""}
                                        </h2>
                                    </GridItem>
                                    <GridItem key={24533} md={3} sm={12}>
                                        <Chip label={chisLabel} />
                                    </GridItem>
                                </GridContainer>
                                <h6>
                                    {I18n.t("productPage.strains")}:{" "}
                                    <i>
                                        {kind.complete &&
                                            kind.data.strains &&
                                            kind.data.strains.map((strain, i, arr) => (
                                                <span>
                                                    {strain.name}
                                                    {arr.length - 1 === i ? "" : " - "}{" "}
                                                </span>
                                            ))}
                                        {kind.complete && isEmpty(kind.data.strains) && (
                                            <span>{kind.data.short_description_nl}</span>
                                        )}
                                    </i>
                                </h6>

                                <Accordion
                                    active={[0]}
                                    activeColor="info"
                                    collapses={[
                                        {
                                            title: I18n.t("productPage.description"),
                                            content: (
                                                <p>
                                                    {(kind.pending && I18n.t("loading")) ||
                                                        (kind.complete && (
                                                            <ReactMarkdown
                                                                source={kind.data[`description_${siteLanguage}`]}
                                                            />
                                                        ))}
                                                </p>
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>

                            <GridItem key={33838} md={12} sm={12}>
                                <GridContainer className={classes.pickSize}>
                                    <GridItem key={2929} md={6} sm={6}>
                                        <h3>{I18n.t("productPage.effects")}</h3>
                                        {(kind.pending && I18n.t("loading")) ||
                                            (kind.complete &&
                                                kind.data.tags.map(tag => (
                                                    <div key={tag} className="effect-container">
                                                        {tag.name}
                                                        <CustomLinearProgress
                                                            variant="determinate"
                                                            color="info"
                                                            value={tag.amount}
                                                        />
                                                    </div>
                                                )))}
                                    </GridItem>
                                    <GridItem key={290280928} md={6} sm={6}>
                                        <h3>{I18n.t("productPage.flavors")}</h3>
                                        <div className={classes.flavours}>
                                            {(kind.pending && I18n.t("loading")) ||
                                                (kind.complete &&
                                                    kind.data.flavors.map(flavor => (
                                                        <Chip
                                                            label={flavor.name}
                                                            style={{ backgroundColor: flavor.color }}
                                                            avatar={<Avatar>{flavor.name[0]}</Avatar>}
                                                        />
                                                    )))}
                                        </div>

                                        <h3>{I18n.t("productPage.prices")}</h3>
                                        {prices && prices.half && (
                                            <div>
                                                0.5 gram:{" "}
                                                <Currency quantity={prices.half} currency="EUR" locale="nl_NL" />
                                            </div>
                                        )}
                                        {prices && prices.one && (
                                            <div>
                                                1 gram: <Currency quantity={prices.one} currency="EUR" locale="nl_NL" />
                                            </div>
                                        )}
                                        {prices && prices.two_five && (
                                            <div>
                                                2.5 gram:{" "}
                                                <Currency quantity={prices.two_five} currency="EUR" locale="nl_NL" />
                                            </div>
                                        )}
                                        {prices && prices.five && (
                                            <div>
                                                5 gram:{" "}
                                                <Currency quantity={prices.five} currency="EUR" locale="nl_NL" />
                                            </div>
                                        )}
                                        {prices && prices.joint && (
                                            <div>
                                                1 joint:{" "}
                                                <Currency quantity={prices.joint} currency="EUR" locale="nl_NL" />
                                            </div>
                                        )}
                                        {prices && prices.piece && (
                                            <div>
                                                1 item:{" "}
                                                <Currency quantity={prices.piece} currency="EUR" locale="nl_NL" />
                                            </div>
                                        )}
                                    </GridItem>
                                </GridContainer>
                                {SHOPS_CONFIG[props.match.params.shopId].showShoppingCart && (
                                    <GridContainer className={classes.pullRight}>
                                        <Button round color="primary" onClick={handleOpen}>
                                            {I18n.t("productPage.addToCart")} &nbsp; <ShoppingCart />
                                        </Button>
                                    </GridContainer>
                                )}
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()}, {shop.complete ? shop.data.name : ""}
                        </div>
                    </div>
                }
            />
        </div>
    );
}
