// Interpolation works as follows:
//
// Make a key with the translation and enclose the variable with {{}}
// ie "Hello {{name}}" Do not add any spaces around the variable name.
// Provide the values as: I18n.t("key", {name: "John Doe"})
import I18n from "i18n-js";

I18n.translations.en = {
    code: "EN",
    name: "English",
    select_locale: "Select English",
    loading: "Loading...",
    EntityId: "",
    header: {
        shoppingCartPay: "Pay"
    },
    chooseProductKindPage: {
        title: "Choose a category"
    },
    shopPage: {
        title: "Prices",
        category: "Category",
        filter: "Filter"
    },
    productPage: {
        description: "Description",
        flavors: "Flavors",
        effects: "Effects",
        addToCart: "Add to cart",
        prices: "Prices",
        price: "Price",
        strains: "Strains"
    },
    checkoutPage: {
        title: "Checkout",
        tableHeader: "Products",
        productColumnName: "Item",
        priceColumnName: "Price",
        orderButton: "Order",
        processingHeader: "Order in progress",
        customerOrderId: "Order-number",
        noTableFound: "No table found. Please scan the QR code of a table in the shop to continue.",
        orderProcessHelpText: "Patience, your order is being prepared. This page will reload when the order is ready.",
        MAX_5_GRAMS_ALLOWED: "The maximum amount of cannabis in an order is 5 grams!",
        OUT_OF_STOCK: "isn't in stock currently. Please remove and try again.",
        table1: "Table 1",
        table2: "Table 2",
        table3: "Table 3",
        table4: "Table 4",
        table5: "Table 5",
        table6: "Table 6",
        table7: "Table 7",
        table8: "Table 8",
        table9: "Table 9",
        table10: "Table 10"
    },
    shoppingCartModal: {
        selectQuantity: "Select a quantity",
        add: "Add",
        close: "Close"
    }
};

export default I18n.translations.en;
