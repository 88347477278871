import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import chooseProductKindPageStyle from "assets/jss/material-kit-pro-react/views/chooseProductKindPageStyle";
import Footer from "components/Footer/Footer.js";
import Header from "components/HeaderShop/Header.js";
import HeaderLinks from "components/HeaderShop/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import I18n from "i18n-js";
import React, { useEffect } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { API_URL, SHOPS_CONFIG } from "../../utils/Constants";
import { useEndpoint, useLocalStorage } from "../../utils/Utils";

const useStyles = makeStyles(chooseProductKindPageStyle);

export default function ChooseProductKindPage(props) {
    const api = `${API_URL}/v1/shops/${props.match.params.shopId}`;
    const shop = useEndpoint({
        method: "GET",
        url: `${api}`
    });

    const [selectedMainCategory, setSelectedMainCategory] = React.useState(undefined);
    const [selectedTable, setSelectedTable] = useLocalStorage(`selectedTable-${props.match.params.shopId}`, "unknown");

    const [loadStorage, setLoadStorage] = useQueryParam("storage", BooleanParam);
    // const [scroll, setScroll] = useLocalStorage("scroll", 0);
    // const executeScroll = () => window.scroll(0, scroll);

    const [shoppingCartStore, setShoppingCartStore] = useLocalStorage(
        `shoppingCartStore-${props.match.params.shopId}`,
        []
    );
    const [shoppingCart, setShoppingCart] = React.useState(shoppingCartStore);

    const toggleCannabis = () => {
        if (selectedMainCategory === "Cannabis") {
            setSelectedMainCategory(undefined);
        } else {
            setSelectedMainCategory("Cannabis");
        }
    };

    const toggleHoreca = () => {
        if (selectedMainCategory === "Horeca") {
            setSelectedMainCategory(undefined);
        } else {
            if (horecaCategories.length === 1) {
                window.location.href = `/shop/${props.match.params.shopId}/category/${horecaCategories[0].categoryId}`;
            } else {
                setSelectedMainCategory("Horeca");
            }
        }
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (props.match.params.hasOwnProperty("tableId")) {
            setSelectedTable(props.match.params.tableId);
        }
    });

    const classes = useStyles();

    let cannabisCategories = [];
    if (shop.complete) {
        for (var category of shop.data.prices.filter(price => price.main_category_name === "Cannabis")) {
            if (!cannabisCategories.find(item => item.categoryId === category.category_id)) {
                cannabisCategories.push({ categoryName: category.category_name, categoryId: category.category_id });
            }
        }
    }
    console.log("cannabisCategories:", cannabisCategories);

    let horecaCategories = []; // {categoryId: uuid, categoryName: "floemp"}
    if (shop.complete) {
        for (var category of shop.data.prices.filter(price => price.main_category_name === "Horeca")) {
            if (horecaCategories.find(item => item.categoryId === category.category_id)) {
            } else {
                horecaCategories.push({ categoryName: category.category_name, categoryId: category.category_id });
            }
        }
    }
    console.log("horecaCategories:", horecaCategories);

    return (
        <div>
            <Header
                brand={shop.complete ? shop.data.name : ""}
                brandLink={`/shop/${props.match.params.shopId}?storage=1`}
                links={
                    <HeaderLinks
                        dropdownHoverColor="rose"
                        showShoppingCart={SHOPS_CONFIG[props.match.params.shopId].showShoppingCart}
                        shoppingCartItems={shoppingCart}
                        shopId={props.match.params.shopId}
                    />
                }
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 20,
                    color: "dark"
                }}
            />
            <Parallax image={require("assets/img/weed2.jpg")} filter="dark" small />
            <div className={classes.main}>
                <>
                    <div className={classes.container}>
                        <h3 className={classes.cardTitle} style={{ paddingTop: "15px" }}>
                            {I18n.t("chooseProductKindPage.title")}
                        </h3>
                        <Button
                            onClick={() => toggleCannabis()}
                            variant="contained"
                            size="large"
                            style={{ margin: "20px 0 20px 0" }}
                        >
                            Cannabis {selectedMainCategory !== "Cannabis" && <ExpandMoreIcon />}
                            {selectedMainCategory === "Cannabis" && <ExpandLessIcon />}
                        </Button>
                        <div className="options">
                            {selectedMainCategory === "Cannabis" &&
                                cannabisCategories.map(item => (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        size="large"
                                        style={{ margin: "20px 10px 20px 10px" }}
                                    >
                                        <a href={`/shop/${props.match.params.shopId}/category/${item.categoryId}`}>
                                            <SmokingRoomsIcon /> &nbsp; {item.categoryName}
                                        </a>
                                    </Button>
                                ))}
                        </div>
                        <Button
                            onClick={() => toggleHoreca()}
                            variant="contained"
                            style={{ margin: "20px 0 20px 0" }}
                            size="large"
                        >
                            Horeca
                        </Button>
                        <div className="options">
                            {selectedMainCategory === "Horeca" &&
                                horecaCategories.map(item => (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        size="large"
                                        style={{ margin: "20px 10px 20px 10px" }}
                                    >
                                        <a href={`/shop/${props.match.params.shopId}/category/${item.categoryId}`}>
                                            <RestaurantIcon /> &nbsp; {item.categoryName}
                                        </a>
                                    </Button>
                                ))}
                        </div>
                    </div>
                    <p style={{ marginTop: 300 }} />
                </>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()}, {shop.complete ? shop.data.name : ""}
                        </div>
                    </div>
                }
            />
        </div>
    );
}
