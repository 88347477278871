// Interpolation works as follows:
//
// Make a key with the translation and enclose the variable with {{}}
// ie "Hello {{name}}" Do not add any spaces around the variable name.
// Provide the values as: I18n.t("key", {name: "John Doe"})
import I18n from "i18n-js";

I18n.translations.nl = {
    code: "NL",
    name: "Nederlands",
    select_locale: "Selecteer Nederlands",
    loading: "Laden...",
    EntityId: "",
    header: {
        shoppingCartPay: "Betaal"
    },
    chooseProductKindPage: {
        title: "Kies een categorie"
    },
    shopPage: {
        title: "Prijzen",
        category: "Categorie",
        filter: "Filter"
    },
    productPage: {
        description: "Beschrijving",
        flavors: "Smaken",
        effects: "Effecten",
        addToCart: "Voeg toe aan",
        prices: "Prijzen",
        price: "Prijs",
        strains: "Soorten"
    },
    checkoutPage: {
        title: "Checkout",
        tableHeader: "Producten",
        productColumnName: "Item",
        priceColumnName: "Prijs",
        orderButton: "Bestel",
        processingHeader: "Bestelling bezig",
        customerOrderId: "Order-nummer",
        noTableFound: "Geen tafel gevonden. Scan a.u.b. de QR code van een table in de shop om verder te gaan.",
        orderProcessHelpText:
            "Even geduld, je bestelling wordt ingepakt, deze pagina ververst zichzelf wanneer de bestelling klaar is.",
        MAX_5_GRAMS_ALLOWED: "Maximaal 5 gram cannabis per klant!",
        OUT_OF_STOCK: "is helaas niet meer op voorraad nu. Verwijder en probeer het opnieuw.",
        table1: "Tafel 1",
        table2: "Tafel 2",
        table3: "Tafel 3",
        table4: "Tafel 4",
        table5: "Tafel 5",
        table6: "Tafel 6",
        table7: "Tafel 7",
        table8: "Tafel 8",
        table9: "Tafel 9",
        table10: "Tafel 10"
    },
    shoppingCartModal: {
        selectQuantity: "Selecteer een hoeveelheid",
        add: "Toevoegen",
        close: "Sluiten"
    }
};

export default I18n.translations.nl;
