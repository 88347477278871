import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Header from "components/Header/Header.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import SectionContact from "./Sections/SectionContact.js";
import SectionDescription from "./Sections/SectionDescription.js";
import SectionOffice from "./Sections/SectionOffice.js";
import SectionServices from "./Sections/SectionServices.js";
import SectionTeam from "./Sections/SectionTeam.js";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header
                brand="Prijslijst.info"
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 300,
                    color: "info"
                }}
            />
            <Parallax image={require("assets/img/bg9.jpg")} filter="dark" small>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem
                            md={8}
                            sm={8}
                            className={classNames(classes.mlAuto, classes.mrAuto, classes.textCenter)}
                        >
                            <h1 className={classes.title}>Over ons</h1>
                            <h4>Prijslijst info is in een publieke beta fase. Meedoen is voor nu invite only.</h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    {/*<SectionDescription />*/}
                    {/*<SectionTeam />*/}
                    {/*<SectionServices />*/}
                    {/*<SectionOffice />*/}
                    <SectionContact />
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.left}>
                            <List className={classes.list}>
                                <ListItem className={classes.inlineBlock}>
                                    <Link to="/over-ons" className={classes.block}>
                                        Over ons
                                    </Link>
                                </ListItem>
                                <ListItem className={classes.inlineBlock}>
                                    <Link to="/contact" className={classes.block}>
                                        Contact
                                    </Link>
                                </ListItem>
                            </List>
                        </div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()} , made with <Favorite className={classes.icon} /> by{" "}
                            <a href="https://www.formatics.nl/?ref=www.prijslijst.info" target="_blank">
                                Formatics
                            </a>{" "}
                            for a better web.
                        </div>
                    </div>
                }
            />
        </div>
    );
}
