import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Face from "@material-ui/icons/Face";
import Favorite from "@material-ui/icons/Favorite";
import image from "assets/img/bg7.jpg";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
/*eslint-disable*/
import React from "react";

const useStyles = makeStyles(loginPageStyle);

export default function LoginPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header absolute color="transparent" brand="Prijslijst.info" />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                            <Card>
                                <form className={classes.form}>
                                    <CardHeader color="primary" signup className={classes.cardHeader}>
                                        <h4 className={classes.cardTitle}>Login</h4>
                                        <div className={classes.socialLine}>
                                            <Button
                                                justIcon
                                                color="transparent"
                                                className={classes.iconButtons}
                                                onClick={e => e.preventDefault()}
                                            >
                                                <i className="fab fa-twitter" />
                                            </Button>
                                            <Button
                                                justIcon
                                                color="transparent"
                                                className={classes.iconButtons}
                                                onClick={e => e.preventDefault()}
                                            >
                                                <i className="fab fa-facebook" />
                                            </Button>
                                            <Button
                                                justIcon
                                                color="transparent"
                                                className={classes.iconButtons}
                                                onClick={e => e.preventDefault()}
                                            >
                                                <i className="fab fa-google-plus-g" />
                                            </Button>
                                        </div>
                                    </CardHeader>
                                    <p className={classes.description + " " + classes.textCenter}>Of klassiek</p>
                                    <CardBody signup>
                                        <CustomInput
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                placeholder: "Email...",
                                                type: "email",
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email className={classes.inputIconsColor} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <CustomInput
                                            id="pass"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                placeholder: "Password",
                                                type: "password",
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                                                    </InputAdornment>
                                                ),
                                                autoComplete: "off"
                                            }}
                                        />
                                    </CardBody>
                                    <div className={classes.textCenter}>
                                        <Button simple color="primary" size="lg">
                                            Get started
                                        </Button>
                                    </div>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
