// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Fingerprint from "@material-ui/icons/Fingerprint";
// @material-ui/icons
import Info from "@material-ui/icons/Info";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import React from "react";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={8}>
                    <h2 className={classes.title}>Prijzen op al uw devices en schermen</h2>
                    <h5 className={classes.description}>
                        Laat hier je e-mail adres achter en je hoort bij de eersten die we informeren zodra we klaar
                        zijn met de productie pilot van de webapplicatie.
                    </h5>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                        <InfoArea
                            title="Houd me op de hoogte"
                            description="Vul hieronder je adres in en we houden je op de hoogte."
                            icon={Info}
                            iconColor="info"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <InfoArea
                            title="Secure en schaalbaar"
                            description="Laat een altijd actuele prijslijst op al uw schermen zien. Ook geschikt voor in-app gebruik."
                            icon={VerifiedUser}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <InfoArea
                            title="Privacy"
                            description="Uitgebreide mogelijkheden qua privacy. Standaard gegarandeerd zonder tracking of reclame van externe partijen."
                            icon={Fingerprint}
                            iconColor="danger"
                            vertical
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
