import { Backdrop, Checkbox } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormatAlignLeft from "@material-ui/core/SvgIcon/SvgIcon";
import Tooltip from "@material-ui/core/Tooltip";
import Favorite from "@material-ui/icons/Favorite";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LocalShipping from "@material-ui/icons/LocalShipping";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Header from "components/HeaderShop/Header.js";
import HeaderLinks from "components/HeaderShop/HeaderLinks.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Parallax from "components/Parallax/Parallax.js";
import I18n from "i18n-js";
import Image from "material-ui-image";
/*eslint-disable*/
import React from "react";
import Currency from "react-currency-formatter";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

import { API_URL, SHOPS_CONFIG } from "../../utils/Constants";
import {
    getProductImageUrl,
    resetCache,
    searchInArray,
    useEndpoint,
    useLocalStorage,
    useSiteLanguage
} from "../../utils/Utils";

const useStyles = makeStyles(productStyle);

export default function ProductPage(props) {
    const executeScroll = () => window.scroll(0, 170);

    const [shoppingCartStore, setShoppingCartStore] = useLocalStorage(
        `shoppingCartStore-${props.match.params.shopId}`,
        []
    );
    const [shoppingCart, setShoppingCart] = React.useState(shoppingCartStore);
    const [siteLanguage, setSiteLanguage] = useSiteLanguage("siteLanguage", "nl");

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddProduct = value => {
        let newShoppingCart = shoppingCart;

        const index = newShoppingCart.findIndex(
            x =>
                x.description === value.description &&
                x.internal_product_id === value.internal_product_id &&
                x.product_id === value.product_id &&
                x.price === value.price
        );
        if (index === -1) {
            value.quantity = 1;
            newShoppingCart.push(value);
        } else {
            newShoppingCart[index].quantity += 1;
        }
        setShoppingCart(newShoppingCart);
        setShoppingCartStore(newShoppingCart);
        handleClose();
    };

    const closeModal = () => {
        handleClose();
    };

    const api = `${API_URL}/v1/shops/${props.match.params.shopId}`;
    const shop = useEndpoint({
        method: "GET",
        url: `${api}`
    });
    const api_product = `${API_URL}/v1/products/${props.match.params.productId}`;
    const product = useEndpoint({
        method: "GET",
        url: `${api_product}`
    });

    const prices = !shop.complete
        ? undefined
        : shop.data.prices.filter(price => price.id === props.match.params.priceId)[0];

    const classes = useStyles();
    const images = [
        {
            original: getProductImageUrl(product.complete ? product.data.image_1 : "", 600),
            thumbnail: getProductImageUrl(product.complete ? product.data.image_1 : "")
        },
        {
            original: getProductImageUrl(product.complete ? product.data.image_2 : "", 600),
            thumbnail: getProductImageUrl(product.complete ? product.data.image_2 : "")
        },
        {
            original: getProductImageUrl(product.complete ? product.data.image_3 : "", 600),
            thumbnail: getProductImageUrl(product.complete ? product.data.image_3 : "")
        }
    ];

    React.useEffect(() => {
        executeScroll();
        return function cleanup() {};
    });

    return (
        <div className={classes.productPage}>
            <Header
                brand={shop.complete ? shop.data.name : ""}
                brandLink={`/shop/${props.match.params.shopId}?storage=1`}
                links={
                    <HeaderLinks
                        dropdownHoverColor="rose"
                        showShoppingCart={SHOPS_CONFIG[props.match.params.shopId].showShoppingCart}
                        shoppingCartItems={shoppingCart}
                        shopId={props.match.params.shopId}
                    />
                }
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 100,
                    color: "dark"
                }}
            />
            <Parallax
                image={require("assets/img/weed_banner_default1.jpg")}
                filter="dark"
                className={classes.pageHeader}
            ></Parallax>
            <div className={classNames(classes.section, classes.sectionGray)}>
                <div className={classes.container}>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <h4 id="transition-modal-title">{I18n.t("shoppingCartModal.selectQuantity")}</h4>
                                <div id="transition-modal-description">
                                    {prices && prices.piece && (
                                        <div key={1}>
                                            <b>
                                                1 item:{" "}
                                                <Currency quantity={prices.piece} currency="EUR" locale="nl_NL" />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "1",
                                                        price: prices.piece,
                                                        kind_id: null,
                                                        kind_name: null,
                                                        product_id: prices.product_id,
                                                        product_name: prices.product_name,
                                                        internal_product_id: prices.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    <Button onClick={closeModal}>{I18n.t("shoppingCartModal.close")}</Button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        <div className={classes.space}>
                            <Link
                                to={`/shop/${props.match.params.shopId}/category/${props.match.params.categoryId}`}
                                activeClassName="current"
                            >
                                <KeyboardBackspaceIcon />
                            </Link>
                        </div>
                        <GridContainer key={113}>
                            <GridItem key={28092890} md={6} sm={6}>
                                <Image
                                    src={getProductImageUrl(product.complete ? product.data.image_1 : "", 600)}
                                ></Image>
                            </GridItem>
                            <GridItem key={209209} md={6} sm={6}>
                                <h2 className={classes.title} onClick={resetCache}>
                                    {product.complete ? product.data.name : ""}
                                </h2>

                                <Accordion
                                    active={[0]}
                                    activeColor="info"
                                    collapses={[
                                        {
                                            title: I18n.t("productPage.description"),
                                            content: (
                                                <p>
                                                    {(product.pending && I18n.t("loading")) ||
                                                        (product.complete && (
                                                            <ReactMarkdown
                                                                source={product.data[`description_${siteLanguage}`]}
                                                            />
                                                        ))}
                                                </p>
                                            )
                                        }
                                    ]}
                                />

                                <div>
                                    <h3>{I18n.t("productPage.price")}</h3>
                                    {prices && prices.piece && (
                                        <Currency quantity={prices.piece} currency="EUR" locale="nl_NL" />
                                    )}
                                </div>
                            </GridItem>
                        </GridContainer>
                        {SHOPS_CONFIG[props.match.params.shopId].showShoppingCart && (
                            <GridContainer className={classes.pullRight}>
                                <Button round color="primary" onClick={handleOpen} className={classes.negspace}>
                                    {I18n.t("productPage.addToCart")} &nbsp; <ShoppingCart />
                                </Button>
                            </GridContainer>
                        )}
                    </div>
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()}, {shop.complete ? shop.data.name : ""}
                        </div>
                    </div>
                }
            />
        </div>
    );
}
