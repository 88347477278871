export const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "https://api.prijslijst.info";

export const PRICELIST_STORE = "pricelist_v1";
export const TEST_USERS = ["acidjunk@gmail.com", "rene@formatics.nl", "rene@inz.nl"];

// Todo: hard coded for now
export const SHOPS_CONFIG = {
    "9c8213fe-4ad9-4136-8b8d-4aed57506703": { name: "Easy going", showShoppingCart: true },
    "19149768-691c-40d8-a08e-fe900fd23bc0": { name: "Mississippi", showShoppingCart: true },
    "a08a13e2-a31b-4b6d-b2b4-0491cb3d2227": { name: "Missouri", showShoppingCart: false },
    "bbc0cfa7-e230-4338-817a-55190debdac0": { name: "Mary Jane", showShoppingCart: false },
    "597c8bfe-8ea3-4967-b6d6-0b337e7d95a2": { name: "Reneekes Test Shop", showShoppingCart: true }
};
