import { container, description, grayColor, title } from "assets/jss/material-kit-pro-react.js";

const sectionSubscribeLineStyle = {
    container,
    title,
    description,
    section: {
        padding: "1.875rem 0"
    },
    cardClasses: {
        marginTop: "30px"
    },
    icon: {
        color: grayColor[13]
    },
    formControl: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        margin: "0px !important"
    },
    alignItemsCenter: {
        display: "flex",
        alignItems: "center"
    }
};

export default sectionSubscribeLineStyle;
