import axios from "axios";

import { API_URL } from "../utils/Constants";

// TODO: CLEANUP we are not using axios as the only data fetching ATM is done via useEndpoint()
const client = axios.create({
    // baseURL: `http://localhost:5000/`
    baseURL: `${API_URL}/`
});

function lookupData(response) {
    return response.data;
}

function checkStatus(response) {
    // Todo: just rely on the axios catch: not sure if this works at all (copied from the old client)
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error); // eslint-disable-line no-console
    throw error;
}

export function getShops(cb) {
    // Todo: add catch function
    return client
        .get("/v1/shops/")
        .then(checkStatus)
        .then(lookupData)
        .then(cb);
}

export function getCategories(shopId, cb) {
    // Todo: add catch function
    return client
        .get(`/v1/categories/shop/${shopId}`)
        .then(checkStatus)
        .then(lookupData)
        .then(cb);
}

export function getProducts(shopId, categoryId, cb) {
    // Todo: add catch function
    return client
        .get(`/v1/products/shop/${shopId}/category/${categoryId}`)
        .then(checkStatus)
        .then(lookupData)
        .then(cb);
}

export function getProduct(shopId, categoryId, productId, cb) {
    // Todo: add catch function
    return client
        .get(`/v1/products/shop/${shopId}/category/${categoryId}/product/${productId}`)
        .then(checkStatus)
        .then(lookupData)
        .then(cb);
}

export default client;
