import { Backdrop } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import product1 from "assets/img/mocks/1.jpg";
import product2 from "assets/img/mocks/2.jpg";
import product3 from "assets/img/mocks/3.jpg";
import product4 from "assets/img/mocks/4.jpg";
import product5 from "assets/img/mocks/5.png";
import product6 from "assets/img/mocks/6.jpg";
import product7 from "assets/img/mocks/7.jpg";
import product8 from "assets/img/mocks/8.jpg";
import shopPageStyle from "assets/jss/material-kit-pro-react/views/shopPageStyle";
// core components
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/HeaderShop/Header.js";
import HeaderLinks from "components/HeaderShop/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import I18n from "i18n-js";
/*eslint-disable*/
import React, { useEffect } from "react";
import Currency from "react-currency-formatter";
import { Link } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import PriceTable from "../../components/PriceTable";
import ProductImage from "../../components/ProductImage";
import Table from "../../components/Table/Table";
import { API_URL, SHOPS_CONFIG } from "../../utils/Constants";
import { isEmpty, resetCache, useEndpoint, useLocalStorage, useSiteLanguage } from "../../utils/Utils";

// sections for this page

const useStyles = makeStyles(shopPageStyle);

export default function ShopPage(props) {
    const api = `${API_URL}/v1/shops/${props.match.params.shopId}`;
    const shop = useEndpoint({
        method: "GET",
        url: `${api}`
    });

    const categoryId = props.match.params.categoryId;
    const categoryName = shop.complete
        ? shop.data.prices.find(price => price.category_id === categoryId).category_name
        : "";

    console.log("categoryId", categoryId);

    const [loadStorage, setLoadStorage] = useQueryParam("storage", BooleanParam);
    const [siteLanguage, setSiteLanguage] = useSiteLanguage("siteLanguage", "nl");
    const [checkedCHISStore, setCheckedCHISStore] = useLocalStorage("checkedCHISStore", "All");
    const [shoppingCartStore, setShoppingCartStore] = useLocalStorage(
        `shoppingCartStore-${props.match.params.shopId}`,
        []
    );
    const [scroll, setScroll] = useLocalStorage("scroll", 0);
    const executeScroll = () => window.scroll(0, scroll);

    const [shoppingCart, setShoppingCart] = React.useState(shoppingCartStore);

    const [selectedPrice, setSelectedPrice] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const showCart = price => {
        setSelectedPrice(price);
        setOpen(true);
    };

    const handleAddProduct = value => {
        let newShoppingCart = shoppingCart;
        let index = -1;
        if (value.hasOwnProperty("kind_id") && !isEmpty(value.kind_id)) {
            index = newShoppingCart.findIndex(
                x =>
                    x.description === value.description &&
                    x.internal_product_id === value.internal_product_id &&
                    x.kind_id === value.kind_id &&
                    x.price === value.price
            );
        } else {
            index = newShoppingCart.findIndex(
                x =>
                    x.description === value.description &&
                    x.internal_product_id === value.internal_product_id &&
                    x.product_id === value.product_id &&
                    x.price === value.price
            );
        }
        if (index === -1) {
            value.quantity = 1;
            newShoppingCart.push(value);
        } else {
            newShoppingCart[index].quantity += 1;
        }
        setShoppingCart(newShoppingCart);
        setShoppingCartStore(newShoppingCart);
        handleClose();
    };

    const closeModal = () => {
        handleClose();
    };

    // Setup selected categories with selected "ALL" from localstorage when requested
    // Todo: check store before mount?
    const [checkedCategory, setCheckedCategory] = React.useState([categoryId]);
    const [checkedCHIS, setCheckedCHIS] = React.useState(loadStorage ? checkedCHISStore : "All");
    let categories = ["All"];
    if (shop.complete) {
        categories.push(...Array.from(new Set(shop.data.prices.map(s => s.category_name))));
    }
    const product_images = [product1, product2, product3, product4, product5, product6, product7, product8];

    useEffect(() => {
        setLoadStorage(true);
        executeScroll();
        return function cleanup() {};
    });

    let pricesFiltered = [];
    if (shop.complete) {
        pricesFiltered = shop.data.prices.filter(price => price.category_id === categoryId);
    }
    // Might be usefull in the future when we want to filter inside a category:
    if (checkedCHIS !== "All") {
        pricesFiltered = pricesFiltered.filter(price => price[kindSort] === true);
    }
    console.log("pricesFiltered", pricesFiltered);

    const classes = useStyles();

    return (
        <div>
            <Header
                brand={shop.complete ? shop.data.name : ""}
                brandLink={`/shop/${props.match.params.shopId}?storage=1`}
                links={
                    <HeaderLinks
                        dropdownHoverColor="rose"
                        showShoppingCart={SHOPS_CONFIG[props.match.params.shopId].showShoppingCart}
                        shoppingCartItems={shoppingCart}
                        shopId={props.match.params.shopId}
                    />
                }
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 20,
                    color: "dark"
                }}
            />
            <Parallax image={require("assets/img/weed2.jpg")} filter="dark" small />
            <div className={classes.main}>
                <div className={classes.container}>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <h4 id="transition-modal-title">{I18n.t("shoppingCartModal.selectQuantity")}</h4>
                                <div id="transition-modal-description">
                                    {selectedPrice && selectedPrice.half && (
                                        <div key={1}>
                                            <b>
                                                0,5 gram:{" "}
                                                <Currency quantity={selectedPrice.half} currency="EUR" locale="nl_NL" />
                                            </b>{" "}
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "0,5 gram",
                                                        price: selectedPrice.half,
                                                        kind_id: selectedPrice.kind_id,
                                                        kind_name: selectedPrice.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: selectedPrice.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {selectedPrice && selectedPrice.one && (
                                        <div key={2}>
                                            <b>
                                                1 gram:{" "}
                                                <Currency quantity={selectedPrice.one} currency="EUR" locale="nl_NL" />
                                            </b>{" "}
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "1 gram",
                                                        price: selectedPrice.one,
                                                        kind_id: selectedPrice.kind_id,
                                                        kind_name: selectedPrice.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: selectedPrice.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {selectedPrice && selectedPrice.two_five && (
                                        <div key={3}>
                                            <b>
                                                2.5 gram:{" "}
                                                <Currency
                                                    quantity={selectedPrice.two_five}
                                                    currency="EUR"
                                                    locale="nl_NL"
                                                />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "2,5 gram",
                                                        price: selectedPrice.two_five,
                                                        kind_id: selectedPrice.kind_id,
                                                        kind_name: selectedPrice.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: selectedPrice.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {selectedPrice && selectedPrice.five && (
                                        <div key={4}>
                                            <b>
                                                5 gram:{" "}
                                                <Currency quantity={selectedPrice.five} currency="EUR" locale="nl_NL" />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "5 gram",
                                                        price: selectedPrice.five,
                                                        kind_id: selectedPrice.kind_id,
                                                        kind_name: selectedPrice.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: selectedPrice.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {selectedPrice && selectedPrice.joint && (
                                        <div key={5}>
                                            <b>
                                                1 joint:{" "}
                                                <Currency
                                                    quantity={selectedPrice.joint}
                                                    currency="EUR"
                                                    locale="nl_NL"
                                                />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "1 joint",
                                                        price: selectedPrice.joint,
                                                        kind_id: selectedPrice.kind_id,
                                                        kind_name: selectedPrice.kind_name,
                                                        product_id: null,
                                                        product_name: null,
                                                        internal_product_id: selectedPrice.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    {selectedPrice && selectedPrice.piece && (
                                        <div key={6}>
                                            <b>
                                                1 item:{" "}
                                                <Currency
                                                    quantity={selectedPrice.piece}
                                                    currency="EUR"
                                                    locale="nl_NL"
                                                />
                                            </b>
                                            <Button
                                                onClick={() =>
                                                    handleAddProduct({
                                                        description: "1",
                                                        price: selectedPrice.piece,
                                                        kind_id: null,
                                                        kind_name: null,
                                                        product_id: selectedPrice.product_id,
                                                        product_name: selectedPrice.product_name,
                                                        internal_product_id: selectedPrice.internal_product_id
                                                    })
                                                }
                                            >
                                                {I18n.t("shoppingCartModal.add")}
                                            </Button>
                                        </div>
                                    )}
                                    <Button onClick={closeModal}>{I18n.t("shoppingCartModal.close")}</Button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>

                    <h1 onClick={resetCache}>{I18n.t("shopPage.title")}</h1>
                    {(shop.pending && I18n.t("loading")) ||
                        (shop.complete && (
                            <React.Fragment>
                                <h2>{categoryName}</h2>

                                <Table
                                    tableData={pricesFiltered.map(price => [
                                        <div className={classes.imgContainer} key={`image-${price.id}`} id={price.id}>
                                            <Link
                                                to={
                                                    price.kind_id
                                                        ? `/shop/${props.match.params.shopId}/price/${price.id}/kind/${price.kind_id}/category/${categoryId}`
                                                        : `/shop/${props.match.params.shopId}/price/${price.id}/product/${price.product_id}/category/${categoryId}`
                                                }
                                                className={classes.tdNameAnchor}
                                                onClick={() => setScroll(window.pageYOffset + 25)}
                                            >
                                                <ProductImage
                                                    name={price.kind_id ? price.kind_image : price.product_image}
                                                />
                                            </Link>
                                        </div>,
                                        <>
                                            <div key={`description-${price.id}`}>
                                                {price.kind_id && (
                                                    <>
                                                        <Link
                                                            to={`/shop/${props.match.params.shopId}/price/${price.id}/kind/${price.kind_id}/category/${categoryId}`}
                                                            onClick={() => setScroll(window.pageYOffset + 25)}
                                                            className={classes.tdNameAnchor}
                                                        >
                                                            {price.kind_name}
                                                        </Link>{" "}
                                                        <IconButton size={"small"} onClick={() => showCart(price)}>
                                                            <Icon size={"small"}>add_circle</Icon>
                                                        </IconButton>
                                                        <br />
                                                        {price.kind_strains &&
                                                            price.kind_strains.map((strain, i, arr) => (
                                                                <span>
                                                                    {strain.name}
                                                                    {arr.length - 1 === i ? "" : " - "}{" "}
                                                                </span>
                                                            ))}
                                                        {isEmpty(price.kind_strains) && (
                                                            <span>
                                                                {price[`kind_short_description_${siteLanguage}`]}
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                                {price.product_id && (
                                                    <>
                                                        <Link
                                                            to={`/shop/${props.match.params.shopId}/price/${price.id}/product/${price.product_id}/category/${categoryId}`}
                                                            onClick={() => setScroll(window.pageYOffset + 25)}
                                                            className={classes.tdNameAnchor}
                                                        >
                                                            {price.product_name}
                                                        </Link>
                                                        <IconButton size={"small"} onClick={() => showCart(price)}>
                                                            <Icon size={"small"}>add_circle</Icon>
                                                        </IconButton>
                                                        <br />
                                                        {price[`product_short_description_${siteLanguage}`]}
                                                    </>
                                                )}
                                            </div>
                                            <div>&nbsp;</div>
                                            <small className={classes.tdNameSmall}>
                                                <PriceTable price={price} />
                                            </small>
                                        </>
                                    ])}
                                    customCellClasses={[classes.imageColumn, classes.productColumn]}
                                    customClassesForCells={[0, 1]}
                                />
                            </React.Fragment>
                        ))}
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()}, {shop.complete ? shop.data.name : ""}
                        </div>
                    </div>
                }
            />
        </div>
    );
}
