import { description, mlAuto, mrAuto, title } from "assets/jss/material-kit-pro-react.js";

const servicesStyle = {
    title,
    mrAuto,
    mlAuto,
    services: {
        paddingTop: "10px",
        paddingBottom: "80px"
    },
    textCenter: {
        textAlign: "center"
    },
    description
};

export default servicesStyle;
