import { container, description, mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const descriptionStyle = {
    container,
    textCenter: {
        textAlign: "center"
    },
    aboutDescription: {
        padding: "70px 0 0 0"
    },
    mrAuto,
    mlAuto,
    description
};

export default descriptionStyle;
