import { cardTitle, coloredShadow, grayColor, title } from "assets/jss/material-kit-pro-react.js";

const sectionInterestedStyle = {
    title,
    cardTitle,
    coloredShadow,
    textCenter: {
        textAlign: "center"
    },
    section: {
        backgroundPosition: "50%",
        backgroundSize: "cover",
        padding: "70px 0"
    },
    description: {
        color: grayColor[0]
    }
};

export default sectionInterestedStyle;
