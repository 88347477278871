// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

const useStyles = makeStyles(contactStyle);

export default function SectionContact() {
    const [specialitySelect, setSpecialitySelect] = React.useState("1");
    const handleSpeciality = event => {
        setSpecialitySelect(event.target.value);
    };
    const classes = useStyles();
    return (
        <div className={classes.aboutUs}>
            <GridContainer>
                <GridItem md={8} sm={8} className={classNames(classes.mrAuto, classes.mlAuto)}>
                    <h2 className={classNames(classes.title, classes.textCenter)}>Meedoen?</h2>
                    <h4 className={classNames(classes.description, classes.textCenter)}>
                        Meedoen is op invite basis only. Vul hieronder je gegevens in en je bent de eerste die van ons
                        hoort zodra we klaar zijn met de pilot.
                    </h4>
                    <form>
                        <GridContainer>
                            <GridItem md={3} sm={3}>
                                <CustomInput
                                    labelText="Naam"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem md={4} sm={4}>
                                <CustomInput
                                    labelText="Email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem md={5} sm={5}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl + " " + classes.selectUnderlineRoot}
                                >
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        value={specialitySelect}
                                        onChange={handleSpeciality}
                                        inputProps={{
                                            name: "specialitySelect",
                                            id: "speciality-select"
                                        }}
                                    >
                                        <MenuItem
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Speciality
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value="1"
                                        >
                                            Ik heb een snackbar
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value="2"
                                        >
                                            Ik heb een restaurant
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value="3"
                                        >
                                            Anders
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem
                                md={4}
                                sm={4}
                                className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)}
                            >
                                <Button color="primary" round>
                                    Let{"'"}s talk
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}
