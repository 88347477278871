import List from "@material-ui/core/List";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import blog8 from "assets/img/examples/blog8.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";
import office2 from "assets/img/examples/office2.jpg";
import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import I18n from "i18n-js";
import React, { useState } from "react";

import { useEndpoint } from "../../../utils/Utils";

const useStyles = makeStyles(sectionPillsStyle);

export default function SectionPills() {
    const api = "https://api.prijslijst.info/v1/shops";
    const shops = useEndpoint({
        method: "GET",
        url: `${api}`
    });

    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer>
                {(shops.pending && I18n.t("loading")) ||
                    (shops.complete &&
                        shops.data.map(item => (
                            <GridItem key={item.id} xs={12} sm={6} md={6}>
                                <Card
                                    raised
                                    background
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            `https://www.prijslijst.info/static/uploaded/shops/${item.id}.png` +
                                            ")"
                                    }}
                                >
                                    <CardBody background>
                                        <h6 className={classes.category}>shop</h6>
                                        <a href={`/shop/${item.id}`}>
                                            <h3 className={classes.cardTitle}>{item.name}</h3>
                                        </a>
                                        <p className={classes.category}>{item.description}</p>
                                        <Button round href={`/shop/${item.id}`} color="danger">
                                            <FormatAlignLeft className={classes.icons} /> View shop
                                        </Button>
                                    </CardBody>
                                </Card>{" "}
                            </GridItem>
                        )))}
            </GridContainer>
        </div>
    );
}
