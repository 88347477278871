// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
    const classes = useStyles();
    return (
        <div className={classNames(classes.aboutDescription, classes.textCenter)}>
            <GridContainer>
                <GridItem md={8} sm={8} className={classNames(classes.mrAuto, classes.mlAuto)}>
                    <h5 className={classes.description}>
                        This is the paragraph where you can write more details about your product. Keep you user engaged
                        by providing meaningful information. Remember that by this time, the user is curious, otherwise
                        he wouldn
                        {"'"}t scroll to get here. Add a button if you want the user to see more.
                    </h5>
                </GridItem>
            </GridContainer>
        </div>
    );
}
