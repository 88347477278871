import * as axios from "axios";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

const isBrowser = typeof window !== `undefined`;

export function stop(e) {
    if (e !== undefined && e !== null) {
        e.preventDefault();
        e.stopPropagation();
    }
}

export function isEmpty(obj) {
    if (obj === undefined || obj === null) {
        return true;
    }
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    if (typeof obj === "string") {
        return obj.trim().length === 0;
    }
    if (typeof obj === "object") {
        return Object.keys(obj).length === 0;
    }
    return false;
}

export function imageUrlForShop(shopId) {
    return `https://api.prijslijst.info/qr/shop/${shopId}`;
}

export function imageUrlForCategory(shopId, categoryId) {
    return `https://api.prijslijst.info/qr/shop/${shopId}/category/${categoryId}`;
}

export function imageUrlForProduct(shopId, categoryId, productId) {
    return `https://api.prijslijst.info/qr/shop/${shopId}/category/${categoryId}/product/${productId}`;
}

export function endsWithAny(suffixes, string) {
    return suffixes.some(function(suffix) {
        return string.endsWith(suffix);
    });
}

export function searchInArray(targetArray, lookupArray, caseSensitiveSearch) {
    return targetArray.filter(function(x) {
        return lookupArray.every(function(lookup) {
            if (x[lookup.key] === undefined) throw new Error("No " + lookup.key + " property in object " + x);

            if (typeof x[lookup.key] !== typeof lookup.value)
                throw new Error("Type mismatch on property " + lookup.key + " + in object " + x);

            if (typeof lookup.value === "string" && caseSensitiveSearch)
                return x[lookup.key].toLowerCase() === lookup.value.toLowerCase();
            else return x[lookup.key] === lookup.value;
        });
    });
}

export function useEndpoint(req) {
    const [res, setRes] = useState({
        data: null,
        complete: false,
        pending: false,
        error: false
    });
    useEffect(() => {
        setRes({
            data: null,
            pending: true,
            error: false,
            complete: false
        });
        cachedFetch(req.url)
            .then(r => r.json())
            .then(r => setRes({ data: r, pending: false, error: false, complete: true }));
    }, [req.url]);
    return res;
}

// Hook
export function useLocalStorage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return [storedValue, setValue];
}

export function useSiteLanguage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const appLanguage = localStorage["siteLanguage"];
            return appLanguage ? appLanguage : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        try {
            setStoredValue(value);
            localStorage.setItem("siteLanguage", value);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
        ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

const cachedFetch = (url, options) => {
    let expiry = 5 * 60; // 5 min default
    if (typeof options === "number") {
        expiry = options;
        options = undefined;
    } else if (typeof options === "object") {
        // I hope you didn't set it to 0 seconds
        expiry = options.seconds || expiry;
    }
    // Use the URL as the cache key to sessionStorage
    let cacheKey = url;
    let cached = localStorage.getItem(cacheKey);
    let whenCached = localStorage.getItem(cacheKey + ":ts");
    if (cached !== null && whenCached !== null) {
        // it was in sessionStorage! Yay!
        // Even though 'whenCached' is a string, this operation
        // works because the minus sign converts the
        // string to an integer and it will work.
        let age = (Date.now() - whenCached) / 1000;
        if (age < expiry) {
            let response = new Response(new Blob([cached]));
            return Promise.resolve(response);
        } else {
            // We need to clean up this old key
            localStorage.removeItem(cacheKey);
            localStorage.removeItem(cacheKey + ":ts");
        }
    }

    return fetch(url, options).then(response => {
        // let's only store in cache if the content-type is
        // JSON or something non-binary
        if (response.status === 200) {
            let ct = response.headers.get("Content-Type");
            if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
                // There is a .json() instead of .text() but
                // we're going to store it in sessionStorage as
                // string anyway.
                // If we don't clone the response, it will be
                // consumed by the time it's returned. This
                // way we're being un-intrusive.
                response
                    .clone()
                    .text()
                    .then(content => {
                        localStorage.setItem(cacheKey, content);
                        localStorage.setItem(cacheKey + ":ts", Date.now());
                    });
            }
        }
        return response;
    });
};

export function resetCache() {
    const items = Object.keys(localStorage);
    console.log(`All cached keys: ${items}`);
    items
        .filter(
            item =>
                item.includes("http") &&
                (item.includes("v1/shop") || item.includes("v1/product") || item.includes("v1/kind"))
        )
        .map(item => {
            console.log(`Removing cached key: ${item}`);
            localStorage.removeItem(item);
        });
    window.location.reload();
}

export function getProductImageUrl(imageName, width = 200) {
    const name = isEmpty(imageName) ? "fallback.png" : imageName;
    const options = {
        bucket: "images-prijslijst-info",
        key: name,
        edits: {
            resize: {
                width: width,
                height: width,
                fit: "contain"
            }
        }
    };
    const strRequest = JSON.stringify(options);
    const encRequest = btoa(strRequest);
    return `https://d3sticxdmgvhkp.cloudfront.net/${encRequest}`;
}
