import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AccountBalance from "@material-ui/icons/AccountBalance";
import AccountCircle from "@material-ui/icons/AccountCircle";
// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Assignment from "@material-ui/icons/Assignment";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Build from "@material-ui/icons/Build";
import Call from "@material-ui/icons/Call";
import Chat from "@material-ui/icons/Chat";
import Dns from "@material-ui/icons/Dns";
import Error from "@material-ui/icons/Error";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Layers from "@material-ui/icons/Layers";
import LineStyle from "@material-ui/icons/LineStyle";
import ListIcon from "@material-ui/icons/List";
import LocationOn from "@material-ui/icons/LocationOn";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Store from "@material-ui/icons/Store";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import Button from "components/CustomButtons/Button.js";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// nodejs library to set properties for components
import PropTypes from "prop-types";
/* eslint-disable */
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const smoothScroll = (e, target) => {
        if (window.location.pathname === "/sections") {
            var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
            if (isMobile) {
                // if we are on mobile device the scroll into view will be managed by the browser
            } else {
                e.preventDefault();
                var targetScroll = document.getElementById(target);
                scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
            }
        }
    };
    const scrollGo = (element, to, duration) => {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function() {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };
    var onClickSections = {};

    const { dropdownHoverColor } = props;
    const classes = useStyles();
    return (
        <List className={classes.list + " " + classes.mlAuto}>
            <ListItem className={classes.listItem}>
                <CustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText=""
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={AccountCircle}
                    dropdownList={[
                        <Link to="/login" className={classes.dropdownLink}>
                            <Error className={classes.dropdownIcons} /> Login
                        </Link>,
                        <Link to="/profiel" className={classes.dropdownLink}>
                            <AccountCircle className={classes.dropdownIcons} /> Profiel
                        </Link>,
                        <Link to="/registreer" className={classes.dropdownLink}>
                            <PersonAdd className={classes.dropdownIcons} /> Registreer
                        </Link>
                    ]}
                />
            </ListItem>
        </List>
    );
}

HeaderLinks.defaultProps = {
    hoverColor: "primary"
};

HeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf(["dark", "primary", "info", "success", "warning", "danger", "rose"])
};
