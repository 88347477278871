import {
    cardTitle,
    container,
    grayColor,
    main,
    mainRaised,
    mlAuto,
    roseColor,
    section,
    title
} from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const productStyle = {
    mlAuto,
    main,
    ...imagesStyles,
    ...customSelectStyle,
    ...tooltipsStyle,
    container: {
        ...container,
        zIndex: 2
    },
    mainRaised: {
        ...mainRaised
    },
    section: {
        ...section,
        padding: "70px 0px"
    },
    title: {
        ...title,
        marginBottom: 0,
        marginTop: 0
    },
    sectionGray: {
        background: grayColor[14]
    },
    mainPrice: {
        margin: "10px 0px 25px"
    },
    textCenter: {
        textAlign: "center!important"
    },
    features: {
        paddingTop: "30px"
    },
    productPage: {
        backgroundColor: grayColor[2],
        "& $mainRaised": {
            margin: "-40vh 0 0",
            padding: "40px"
        },
        "& .image-gallery-slide img": {
            borderRadius: "3px",
            maxWidth: "300px",
            height: "auto"
        },
        "& .image-gallery-swipe": {
            margin: "30px 0px",
            overflow: "hidden",
            width: "100%",
            height: "auto",
            textAlign: "center"
        },
        "& .image-gallery-thumbnails > .image-gallery-thumbnails-container a": {
            "&.active > div": {
                opacity: "1",
                borderColor: grayColor[6]
            },
            "& > div": {
                width: "80%",
                maxWidth: "85px",
                margin: "0 auto",
                padding: "8px",
                display: "block",
                border: "1px solid transparent",
                background: "transparent",
                borderRadius: "3px",
                opacity: ".8"
            },
            "& > div img": {
                borderRadius: "3px",
                width: "100%",
                height: "auto",
                textAlign: "center"
            }
        }
    },
    titleRow: {
        marginTop: "-8vh"
    },
    floatRight: {
        float: "right!important"
    },
    pageHeader: {
        minHeight: "60vh",
        maxHeight: "600px",
        height: "auto",
        backgroundPosition: "top center"
    },
    relatedProducts: {
        marginTop: "50px",
        "& $title": {
            marginBottom: "80px"
        }
    },
    pickSize: {
        marginTop: "50px"
    },
    pullRight: {
        float: "right"
    },
    cardCategory: {
        textAlign: "center",
        marginTop: "10px"
    },
    cardTitle: {
        ...cardTitle,
        textAlign: "center"
    },
    cardDescription: {
        textAlign: "center",
        color: grayColor[0]
    },
    textRose: {
        color: roseColor[0]
    },
    justifyContentBetween: {
        justifyContent: "space-between!important"
    },
    socialFeed: {
        "& p": {
            display: "table-cell",
            verticalAlign: "top",
            overflow: "hidden",
            paddingBottom: "10px",
            maxWidth: 300
        },
        "& i": {
            fontSize: "20px",
            display: "table-cell",
            paddingRight: "10px"
        }
    },
    img: {
        width: "20%",
        marginRight: "5%",
        marginBottom: "5%",
        float: "left"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    flavours: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        "& > *": {
            margin: "3px"
        }
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right"
    },
    icon: {
        top: "3px",
        width: "18px",
        height: "18px",
        position: "relative"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        padding: "2px 20px 20px 10px",
        backgroundColor: grayColor[1],
        border: "2px solid " + grayColor[12],
        color: "white",
        boxShadow: 23
    },
    space: {
        paddingBottom: "30px"
    },
    negspace: {
        marginTop: "-30px"
    }
};

export default productStyle;
