// react components for routing our app without refresh
import { Link } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import AccountBalance from "@material-ui/icons/AccountBalance";
import AccountCircle from "@material-ui/icons/AccountCircle";
// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Assignment from "@material-ui/icons/Assignment";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Build from "@material-ui/icons/Build";
import Call from "@material-ui/icons/Call";
import Chat from "@material-ui/icons/Chat";
import Dns from "@material-ui/icons/Dns";
import Error from "@material-ui/icons/Error";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Layers from "@material-ui/icons/Layers";
import LineStyle from "@material-ui/icons/LineStyle";
import ListIcon from "@material-ui/icons/List";
import LocationOn from "@material-ui/icons/LocationOn";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Store from "@material-ui/icons/Store";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import Button from "components/CustomButtons/Button.js";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import I18n from "i18n-js";
// nodejs library to set properties for components
import PropTypes from "prop-types";
/* eslint-disable */
import React from "react";

import { countryToFlag, useSiteLanguage } from "../../utils/Utils";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    var onClickSections = {};

    const { dropdownHoverColor, showShoppingCart } = props;

    const [siteLanguage, setSiteLanguage] = useSiteLanguage("siteLanguage", "nl");

    const changeLanguage = () => {
        if (siteLanguage === "nl") {
            console.log("Switching to EN");
            setSiteLanguage("en");
            I18n.locale = "en";
        } else {
            console.log("Switching to NL");
            setSiteLanguage("nl");
            I18n.locale = "nl";
        }
        location.reload();
    };

    const classes = useStyles();
    return (
        <List className={classes.list + " " + classes.mlAuto}>
            <ListItem>
                <Link
                    onClick={() => {
                        changeLanguage("en");
                    }}
                >
                    <div className={classes.languageSelect}>
                        {countryToFlag(siteLanguage === "en" ? "gb" : siteLanguage)}
                    </div>
                </Link>
            </ListItem>
            {showShoppingCart && (
                <ListItem className={classes.listItem}>
                    <Button
                        href={`/shop/${props.shopId}/cart`}
                        color="white"
                        simple={false}
                        className={classes.navButton}
                        round
                    >
                        <ShoppingCart className={classes.icons} />
                        <>
                            <Hidden smDown implementation="css" className={classes.hidden}>
                                {I18n.t("header.shoppingCartPay")}:&nbsp;
                            </Hidden>
                            {props.shoppingCartItems.length}
                        </>
                    </Button>
                </ListItem>
            )}
        </List>
    );
}

HeaderLinks.defaultProps = {
    hoverColor: "primary",
    showShoppingCart: false,
    shoppingCartItems: []
};

HeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf(["dark", "primary", "info", "success", "warning", "danger", "rose"]),
    showShoppingCart: PropTypes.bool,
    shoppingCartItems: PropTypes.array,
    shopId: PropTypes.string.isRequired
};
