import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Phone from "@material-ui/icons/Phone";
import PinDrop from "@material-ui/icons/PinDrop";
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import InfoArea from "components/InfoArea/InfoArea.js";
/*eslint-disable*/
import React from "react";
// react components used to create a google map
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { Link } from "react-router-dom";

import Parallax from "../../components/Parallax/Parallax";

const CustomSkinMap = withScriptjs(
    withGoogleMap(() => (
        <GoogleMap
            defaultZoom={14}
            defaultCenter={{ lat: 44.43353, lng: 26.093928 }}
            defaultOptions={{
                scrollwheel: false,
                zoomControl: true,
                styles: [
                    {
                        featureType: "water",
                        stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.fill",
                        stylers: [{ hue: "#ff0000" }, { saturation: -100 }, { lightness: 99 }]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#808080" }, { lightness: 54 }]
                    },
                    {
                        featureType: "landscape.man_made",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#ece2d9" }]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#ccdca1" }]
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#767676" }]
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#ffffff" }]
                    },
                    { featureType: "poi", stylers: [{ visibility: "off" }] },
                    {
                        featureType: "landscape.natural",
                        elementType: "geometry.fill",
                        stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
                    },
                    { featureType: "poi.park", stylers: [{ visibility: "on" }] },
                    {
                        featureType: "poi.sports_complex",
                        stylers: [{ visibility: "on" }]
                    },
                    { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
                    {
                        featureType: "poi.business",
                        stylers: [{ visibility: "simplified" }]
                    }
                ]
            }}
        >
            <Marker position={{ lat: 44.43353, lng: 26.093928 }} />
        </GoogleMap>
    ))
);

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header brand="Prijslijst.info" links={<HeaderLinks dropdownHoverColor="dark" />} fixed color="dark" />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.contactContent}>
                    <div className={classes.container}>
                        <h2 className={classes.title}>Contact</h2>
                        <GridContainer>
                            <GridItem md={6} sm={6}>
                                <p>
                                    Je mag natuurlijk altijd contact met ons opnemen. Vul het formulier in en we
                                    proberen zo snel mogelijk contact met je op te nemen.
                                    <br />
                                    <br />
                                </p>
                                <form>
                                    <CustomInput
                                        labelText="Naam"
                                        id="float"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Email"
                                        id="float"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Telefoon"
                                        id="float"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Je vraag/opmerking"
                                        id="float"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            multiline: true,
                                            rows: 6
                                        }}
                                    />
                                    <div className={classes.textCenter}>
                                        <Button color="primary" round>
                                            Neem contact op
                                        </Button>
                                    </div>
                                </form>
                            </GridItem>
                            {/*<GridItem md={4} sm={4} className={classes.mlAuto}>*/}
                            {/*  <InfoArea*/}
                            {/*    className={classes.info}*/}
                            {/*    title="Find us at the office"*/}
                            {/*    description={*/}
                            {/*      <p>*/}
                            {/*        Bld Mihail Kogalniceanu, nr. 8, <br /> 7652 Bucharest,{" "}*/}
                            {/*        <br /> Romania*/}
                            {/*      </p>*/}
                            {/*    }*/}
                            {/*    icon={PinDrop}*/}
                            {/*    iconColor="primary"*/}
                            {/*  />*/}
                            {/*  <InfoArea*/}
                            {/*    className={classes.info}*/}
                            {/*    title="Give us a ring"*/}
                            {/*    description={*/}
                            {/*      <p>*/}
                            {/*        Michael Jordan <br /> +40 762 321 762 <br /> Mon - Fri,*/}
                            {/*        8:00-22:00*/}
                            {/*      </p>*/}
                            {/*    }*/}
                            {/*    icon={Phone}*/}
                            {/*    iconColor="primary"*/}
                            {/*  />*/}
                            {/*  <InfoArea*/}
                            {/*    className={classes.info}*/}
                            {/*    title="Legal Information"*/}
                            {/*    description={*/}
                            {/*      <p>*/}
                            {/*        Creative Tim Ltd. <br /> VAT · EN2341241 <br /> IBAN ·*/}
                            {/*        EN8732ENGB2300099123 <br /> Bank · Great Britain Bank*/}
                            {/*      </p>*/}
                            {/*    }*/}
                            {/*    icon={BusinessCenter}*/}
                            {/*    iconColor="primary"*/}
                            {/*  />*/}
                            {/*</GridItem>*/}
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.left}>
                            <List className={classes.list}>
                                <ListItem className={classes.inlineBlock}>
                                    <Link to="/over-ons" className={classes.block}>
                                        Over ons
                                    </Link>
                                </ListItem>
                                <ListItem className={classes.inlineBlock}>
                                    <Link to="/contact" className={classes.block}>
                                        Contact
                                    </Link>
                                </ListItem>
                            </List>
                        </div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()} , made with <Favorite className={classes.icon} /> by{" "}
                            <a href="https://www.formatics.nl/?ref=www.prijslijst.info" target="_blank">
                                Formatics
                            </a>{" "}
                            for a better web.
                        </div>
                    </div>
                }
            />
        </div>
    );
}
