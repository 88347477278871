import "./App.css";
import "./locale/en";
import "./locale/nl";

import I18n from "i18n-js";
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import ChooseProductKindPage from "./pages/ChooseProductKindPage/ChooseProductKindPage";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import ProductLandingPage from "./pages/EmptyShopPage/EmptyShopPage";
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import KindPage from "./pages/ProductPage/KindPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import ShopPage from "./pages/ShopPage/ShopPage";
import ShoppingCartPage from "./pages/ShoppingCartPage/ShoppingCartPage";
import ShopsPage from "./pages/ShopsPage/ShopsPage";
import { isEmpty } from "./utils/Utils";

class App extends Component {
    state = { isLoggedIn: false, user: {}, activeItem: undefined };
    handleItemClick = (e, { name }) => this.setState({ activeItem: name });

    initLanguage() {
        I18n.locale = "nl";
        localStorage.setItem("siteLanguage", JSON.stringify("nl"));
    }

    componentDidMount() {
        // Setup language
        const appLanguage = localStorage["siteLanguage"];
        if (isEmpty(appLanguage)) {
            this.initLanguage();
        } else {
            if (["nl", "en"].includes(appLanguage)) {
                I18n.locale = appLanguage;
            } else {
                // console.log("Error");
                this.initLanguage();
            }
        }
        // Setup default app state
        // (Todo: refactor as we don't use it ATM: we could switch to AppContext with the language in it)
        const appState = localStorage["appState"];
        if (!isEmpty(appState)) {
            const newState = JSON.parse(appState);
            this.setState({ isLoggedIn: newState.isLoggedIn, user: newState.user });
        }
    }

    logout = () => {
        const appState = {
            isLoggedIn: false,
            user: {},
            activeItem: this.state.activeItem
        };
        // save app state with user date in local storage
        localStorage["appState"] = JSON.stringify(appState);
        this.setState(appState);
        window.location.href = "/login";
    };

    render() {
        const { activeItem, user, isLoggedIn } = this.state;

        return (
            <BrowserRouter>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        {/*<Route exact path="/shops" component={ShopsPage} />*/}
                        {/*<Route exact path="/shop/:shopId/cart" component={ShoppingCartPage} />*/}
                        {/*<Route exact path="/shop/:shopId/:tableId" component={ChooseProductKindPage} />*/}
                        {/*<Route exact path="/shop/:shopId" component={ChooseProductKindPage} />*/}
                        {/*<Route exact path="/shop/:shopId/category/:categoryId" component={ShopPage} />*/}
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path="/shop/:shopId/price/:priceId/kind/:kindId/category/:categoryId"*/}
                        {/*    component={KindPage}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path="/shop/:shopId/price/:priceId/product/:productId/category/:categoryId"*/}
                        {/*    component={ProductPage}*/}
                        {/*/>*/}
                        {/*<Route exact path="/shop/:shopId/cart" component={ShoppingCartPage} />*/}
                        <Route path="/over-ons" component={AboutUsPage} />
                        <Route path="/contact" component={ContactUsPage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/emptypage" component={ProductLandingPage} />
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}
export default App;
