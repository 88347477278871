// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-kit-pro-react/components/cardStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// @material-ui/icons

const useStyles = makeStyles(styles);

export default function Card(props) {
    const {
        className,
        children,
        plain,
        profile,
        blog,
        raised,
        background,
        pricing,
        color,
        product,
        testimonial,
        ...rest
    } = props;
    const classes = useStyles();
    const cardClasses = classNames({
        [classes.card]: true,
        [classes.cardPlain]: plain,
        [classes.cardProfile]: profile || testimonial,
        [classes.cardBlog]: blog,
        [classes.cardRaised]: raised,
        [classes.cardBackground]: background,
        [classes.cardPricingColor]: (pricing && color !== undefined) || (pricing && background !== undefined),
        [classes[color]]: color,
        [classes.cardPricing]: pricing,
        [classes.cardProduct]: product,
        [className]: className !== undefined
    });
    return (
        <div className={cardClasses} {...rest}>
            {children}
        </div>
    );
}

Card.propTypes = {
    className: PropTypes.string,
    plain: PropTypes.bool,
    profile: PropTypes.bool,
    blog: PropTypes.bool,
    raised: PropTypes.bool,
    background: PropTypes.bool,
    pricing: PropTypes.bool,
    testimonial: PropTypes.bool,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "rose"]),
    product: PropTypes.bool,
    children: PropTypes.node
};
