import { infoColor } from "assets/jss/material-kit-pro-react";
import {
    cardTitle,
    container,
    grayColor,
    main,
    mainRaised,
    mlAuto,
    mrAuto,
    title,
    whiteColor
} from "assets/jss/material-kit-pro-react.js";
import buttonGroup from "assets/jss/material-kit-pro-react/buttonGroupStyle.js";
import tooltips from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const styles = {
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    cardTitle,
    ...buttonGroup,
    ...tooltips,
    container: {
        ...container,
        zIndex: "2",
        marginTop: "-380px",
        "@media (max-width: 800px)": {
            marginTop: "-220px"
        },
        "@media (max-width: 576px)": {
            marginTop: "-190px"
        }
    },
    title: {
        ...title,
        "&, & + h4": {
            color: whiteColor
        }
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "11px",
        textTransform: "uppercase",
        borderRadius: "1px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right"
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    imgContainer: {
        width: "120px",
        maxHeight: "160px",
        overflow: "hidden",
        display: "block",
        "& img": {
            width: "100%"
        }
    },
    description: {
        maxWidth: "60px"
    },
    tdName: {
        minWidth: "65px",
        fontWeight: "150",
        fontSize: "0.83em"
    },
    tdQuantity: {
        minWidth: "120px",
        fontWeight: "100",
        fontSize: "0.8em"
    },
    tdNameAnchor: {
        color: infoColor[0]
    },
    tdNameSmall: {
        fontSize: "0.75em",
        fontWeight: "300"
    },
    tdNumber: {
        textAlign: "right",
        minWidth: "10px",
        fontWeight: "200",
        fontSize: "1em !important"
    },
    tdNumberSmall: {
        marginRight: "3px",
        minWidth: "63px"
    },
    tdNumberAndButtonGroup: {
        lineHeight: "1 !important",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
            marginRight: "0"
        }
    },
    customFont: {
        fontSize: "16px !important"
    },
    actionButton: {
        margin: "0px",
        padding: "5px"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    totals: {
        paddingTop: "20px",
        fontWeight: "500",
        fontSize: "1.0625rem",
        paddingRight: "5px"
    }
};

export default styles;
