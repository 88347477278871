import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import I18n from "i18n-js";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Header(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles();
    React.useEffect(() => {
        if (props.changeColorOnScroll) {
            window.addEventListener("scroll", headerColorChange);
        }
        return function cleanup() {
            if (props.changeColorOnScroll) {
                window.removeEventListener("scroll", headerColorChange);
            }
        };
    });
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const headerColorChange = () => {
        const { color, changeColorOnScroll } = props;

        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            document.body.getElementsByTagName("header")[0].classList.remove(classes[color]);
            document.body.getElementsByTagName("header")[0].classList.add(classes[changeColorOnScroll.color]);
        } else {
            document.body.getElementsByTagName("header")[0].classList.add(classes[color]);
            document.body.getElementsByTagName("header")[0].classList.remove(classes[changeColorOnScroll.color]);
        }
    };
    const { color, links, brand, brandLink, fixed, absolute } = props;
    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: color,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed
    });
    return (
        <AppBar className={appBarClasses}>
            <Toolbar className={classes.container}>
                <Button className={classes.title}>
                    <Link to={brandLink}>{brand}</Link>
                </Button>
                <div>
                    <>{links}</>
                </div>
                {/*<Hidden smDown implementation="css" className={classes.hidden}>*/}
                {/*    <div className={classes.collapse}>{links}</div>*/}
                {/*</Hidden>*/}
                {/*<Hidden mdUp>*/}
                {/*    <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>*/}
                {/*        <Menu />*/}
                {/*    </IconButton>*/}
                {/*</Hidden>*/}
            </Toolbar>
            {/*<Hidden mdUp implementation="js">*/}
            {/*    <Drawer*/}
            {/*        variant="temporary"*/}
            {/*        anchor={"right"}*/}
            {/*        open={mobileOpen}*/}
            {/*        classes={{*/}
            {/*            paper: classes.drawerPaper*/}
            {/*        }}*/}
            {/*        onClose={handleDrawerToggle}*/}
            {/*    >*/}
            {/*        <IconButton*/}
            {/*            color="inherit"*/}
            {/*            aria-label="open drawer"*/}
            {/*            onClick={handleDrawerToggle}*/}
            {/*            className={classes.closeButtonDrawer}*/}
            {/*        >*/}
            {/*            <Close />*/}
            {/*        </IconButton>*/}
            {/*        <div className={classes.appResponsive}>{links}</div>*/}
            {/*    </Drawer>*/}
            {/*</Hidden>*/}
        </AppBar>
    );
}

Header.defaultProp = {
    color: "white"
};

Header.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "transparent", "white", "rose", "dark"]),
    links: PropTypes.node,
    brand: PropTypes.string,
    brandLink: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark"
        ]).isRequired
    })
};
