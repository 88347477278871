import {
    container,
    grayColor,
    infoColor,
    main,
    mainRaised,
    title,
    whiteColor
} from "assets/jss/material-kit-pro-react.js";

const shopPageStyle = {
    container: {
        ...container,
        zIndex: "2",
        marginTop: "-380px",
        "@media (max-width: 800px)": {
            marginTop: "-220px"
        },
        "@media (max-width: 576px)": {
            marginTop: "-190px"
        }
    },
    imageColumn: {
        textAlign: "center",
        width: "135px"
    },
    title: {
        ...title,
        color: whiteColor
    },
    categoryTitle: {
        color: infoColor[2]
    },
    subtitle: {
        color: whiteColor
    },
    main: {
        ...main,
        ...mainRaised
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right"
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    pullRight: {
        float: "right"
    },
    checked: {
        color: infoColor[0] + "!important"
    },
    cardHeaderImage: {
        position: "relative",
        padding: "0",
        zIndex: "1",
        marginLeft: "15px",
        marginRight: "15px",
        marginTop: "-30px",
        borderRadius: "6px",
        "& img": {
            width: "100%",
            borderRadius: "6px",
            pointerEvents: "none"
        },
        "& a": {
            display: "block"
        }
    },
    justifyContentBetween: {
        WebkitBoxPack: "justify!important",
        justifyContent: "space-between !important"
    },
    priceSlider: {
        fontWeight: "500"
    },
    refineButton: {
        margin: "-3px 0"
    },
    cardBodyRefine: {
        marginTop: "-40px",
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    textLeft: {
        textAlign: "left"
    },
    productColumn: {
        textAlign: "left"
    },
    imgContainer: {
        width: "120px",
        maxHeight: "160px",
        overflow: "hidden",
        display: "block",
        "& img": {
            width: "100%"
        }
    },
    paper: {
        padding: "2px 20px 20px 10px",
        backgroundColor: grayColor[1],
        border: "2px solid " + grayColor[12],
        color: "white",
        boxShadow: 23
    }
};

export default shopPageStyle;
