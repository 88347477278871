import { makeStyles } from "@material-ui/core/styles";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Header from "components/HeaderShop/Header.js";
import HeaderLinks from "components/HeaderShop/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import React, { useEffect } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { API_URL } from "../../utils/Constants";
import { useEndpoint, useLocalStorage } from "../../utils/Utils";

const useStyles = makeStyles(blogPostPageStyle);

export default function ShopPage(props) {
    const api = `${API_URL}/v1/shops/${props.match.params.shopId}`;
    const shop = useEndpoint({
        method: "GET",
        url: `${api}`
    });
    const [loadStorage, setLoadStorage] = useQueryParam("storage", BooleanParam);
    const [scroll, setScroll] = useLocalStorage("scroll", 0);
    const executeScroll = () => window.scroll(0, scroll);

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setLoadStorage(true);
        executeScroll();
        return function cleanup() {};
    });

    const classes = useStyles();

    return (
        <div>
            <Header
                brand={shop.complete ? shop.data.name : ""}
                brandLink={`/shop/${props.match.params.shopId}?storage=1`}
                links={<HeaderLinks dropdownHoverColor="rose" shopId={props.match.params.shopId} />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 20,
                    color: "dark"
                }}
            />
            <Parallax image={require("assets/img/weed2.jpg")} filter="dark" small />
            <div className={classes.main}>
                <div className={classes.container}>
                    <GridContainer></GridContainer>
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()}, {shop.complete ? shop.data.name : ""}
                        </div>
                    </div>
                }
            />
        </div>
    );
}
