import PropTypes from "prop-types";
import React from "react";

import { isEmpty } from "../utils/Utils";

export default function ProductImage(props) {
    const name = isEmpty(props.name) ? "fallback.png" : props.name;
    const options = {
        bucket: "images-prijslijst-info",
        key: name,
        edits: {
            resize: {
                width: props.width,
                height: 200,
                fit: "contain"
            }
        }
    };
    const strRequest = JSON.stringify(options);
    const encRequest = btoa(strRequest);

    return <img width="200" src={`https://d3sticxdmgvhkp.cloudfront.net/${encRequest}`} />;
}

ProductImage.defaultProps = {
    name: "fallback.png",
    width: 200
};

ProductImage.propTypes = {
    name: PropTypes.string,
    width: PropTypes.number
};
