import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Close from "@material-ui/icons/Close";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import InfoIcon from "@material-ui/icons/Info";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import WarningIcon from "@material-ui/icons/Warning";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import clsx from "clsx";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Header from "components/HeaderShop/Header.js";
import HeaderLinks from "components/HeaderShop/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Table from "components/Table/Table.js";
import I18n from "i18n-js";
import PropTypes from "prop-types";
/*eslint-disable*/
import React from "react";
import Currency from "react-currency-formatter";

import client from "../../api/Client";
import { API_URL, SHOPS_CONFIG } from "../../utils/Constants";
import { useEndpoint, useLocalStorage } from "../../utils/Utils";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const useStyles = makeStyles(shoppingCartStyle);

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: "flex",
        alignItems: "center"
    }
}));

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired
};

export default function ShoppingCartPage(props) {
    const api = `${API_URL}/v1/shops/${props.match.params.shopId}`;
    const shop = useEndpoint({
        method: "GET",
        url: `${api}`
    });

    const [shoppingCartStore, setShoppingCartStore] = useLocalStorage(
        `shoppingCartStore-${props.match.params.shopId}`,
        []
    );
    const [shoppingCart, setShoppingCart] = React.useState(shoppingCartStore);
    const [selectedTable, setSelectedTable] = useLocalStorage(`selectedTable-${props.match.params.shopId}`, "unknown");
    const [checkoutTokenStore, setCheckoutTokenStore] = useLocalStorage(
        `checkoutTokenStore-${props.match.params.shopId}`,
        {}
    );
    const [checkoutToken, setCheckoutToken] = React.useState(checkoutTokenStore);
    const [orderErrorMessage, setOrderErrorMessage] = React.useState("");
    const [infoOpen, setInfoOpen] = React.useState(false);

    const total = shoppingCart.map(item => item.price * item.quantity).reduce((a, b) => a + b, 0);

    const removeItem = index => {
        let newShoppingCart = shoppingCart.filter((item, idx) => index !== idx);
        setShoppingCart(newShoppingCart);
        setShoppingCartStore(newShoppingCart);
    };
    const decreaseQuantity = index => {
        let newShoppingCart = shoppingCart;
        if (newShoppingCart[index].quantity === 1) {
            removeItem(index);
        } else {
            newShoppingCart[index].quantity -= 1;
            setShoppingCart([...newShoppingCart]);
            setShoppingCartStore([...newShoppingCart]);
        }
    };

    const increaseQuantity = index => {
        let newShoppingCart = shoppingCart;
        newShoppingCart[index].quantity += 1;
        setShoppingCart([...newShoppingCart]);
        setShoppingCartStore([...newShoppingCart]);
    };

    const completeOrder = () => {
        setOrderErrorMessage("");

        const order = {
            shop_id: props.match.params.shopId,
            table_id: selectedTable,
            order_info: shoppingCart,
            total: total
        };
        client
            .post(`/v1/orders`, order)
            .then(res => {
                console.log(res);
                if (res.status === 201) {
                    const orderToken = {
                        orderId: res.data.id,
                        customerOrderId: res.data.customer_order_id
                    };
                    setCheckoutTokenStore(orderToken);
                    setCheckoutToken(orderToken);
                    setInfoOpen(true);
                    setInterval();
                } else {
                    // debugger
                    console.log("Order NOT OK");
                }
            })
            .catch(res => {
                // debugger;
                // Todo: check fucked up error handling from axios: the catch is also executed on 201!!
                if (res.hasOwnProperty("response") && res.response.status === 400) {
                    setOrderErrorMessage(res.response.data.message);
                    setInfoOpen(true);
                }
            });
    };

    const checkStatus = () => {
        // debugger
        if (checkoutToken.hasOwnProperty("orderId")) {
            client.get(`/v1/orders/${checkoutToken.orderId}`).then(result => {
                console.log(result);
                if (result.data.status === "complete") {
                    setCheckoutTokenStore({});
                    setCheckoutToken({});
                    // Todo: reset + melding laten zien wanneer het gelukt is.
                    // location.href = "/"
                } else if (result.data.status === "cancelled") {
                    setCheckoutTokenStore({});
                    setCheckoutToken({});
                    // Todo: reset + melding laten zien wanneer het gelukt is.
                    // location.href = "/"
                }
            });
        } else {
            console.log("No order check needed.");
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 500);
        document.body.scrollTop = 0;
        const interval = setInterval(() => {
            checkStatus();
        }, 3000);
        return () => clearInterval(interval);
    });
    const classes = useStyles();

    let translatedOrderMessage = orderErrorMessage;
    if (orderErrorMessage !== "" && translatedOrderMessage.includes(",")) {
        const messageParts = translatedOrderMessage.split(",");
        const translationPart = I18n.t(`checkoutPage.${messageParts[1].trim()}`);
        translatedOrderMessage = `${messageParts[0]}, ${translationPart}`;
    } else if (orderErrorMessage !== "") {
        translatedOrderMessage = I18n.t(`checkoutPage.${orderErrorMessage}`);
    }

    return (
        <div>
            <Header
                brand={shop.complete ? shop.data.name : ""}
                brandLink={`/shop/${props.match.params.shopId}?storage=1`}
                links={
                    <HeaderLinks
                        dropdownHoverColor="rose"
                        showShoppingCart={SHOPS_CONFIG[props.match.params.shopId].showShoppingCart}
                        shoppingCartItems={shoppingCart}
                        shopId={props.match.params.shopId}
                    />
                }
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 20,
                    color: "dark"
                }}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={infoOpen}
                autoHideDuration={6000}
                onClose={() => setInfoOpen(false)}
            >
                <MySnackbarContentWrapper
                    onClose={() => setInfoOpen(false)}
                    variant={orderErrorMessage === "" ? "success" : "error"}
                    message={orderErrorMessage === "" ? "Succesvol verstuurd!" : translatedOrderMessage}
                />
            </Snackbar>
            <Parallax image={require("assets/img/weed.jpg")} filter="dark" small>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            md={8}
                            sm={8}
                            className={classNames(classes.mlAuto, classes.mrAuto, classes.textCenter)}
                        >
                            <h2 className={classes.title}>Checkout</h2>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>

            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <Card plain>
                        <CardBody plain>
                            {checkoutToken.hasOwnProperty("orderId") && (
                                <div>
                                    <h3 className={classes.cardTitle}>{I18n.t("checkoutPage.processingHeader")}</h3>
                                    <p>
                                        {I18n.t("checkoutPage.customerOrderId")}: {checkoutToken.customerOrderId}
                                    </p>
                                    <CircularProgress />
                                    <p>
                                        <i>{I18n.t("checkoutPage.orderProcessHelpText")}</i>
                                    </p>
                                </div>
                            )}
                            {!checkoutToken.hasOwnProperty("orderId") && (
                                <div>
                                    <h3 className={classes.cardTitle}>{I18n.t("checkoutPage.tableHeader")}</h3>
                                    {selectedTable === "unknown" && (
                                        <p style={{ color: "red" }}>{I18n.t("checkoutPage.noTableFound")}</p>
                                    )}
                                    <Table
                                        tableHead={[
                                            I18n.t("checkoutPage.productColumnName"),
                                            "#",
                                            "",
                                            I18n.t("checkoutPage.priceColumnName")
                                        ]}
                                        tableData={shoppingCart.map((item, index) => [
                                            <span key={index}>
                                                <a
                                                    href={
                                                        item.kind_name
                                                            ? `/shop/${props.match.params.shopId}/price/${item.price_id}/kind/${item.kind_id}`
                                                            : `/shop/${props.match.params.shopId}/price/${item.price_id}/product/${item.product_id}`
                                                    }
                                                    className={classes.tdNameAnchor}
                                                >
                                                    {item.kind_name ? item.kind_name : item.product_name}
                                                </a>
                                                <br />
                                                <small className={classes.tdNameSmall}>
                                                    {item.description == 1 ? "" : item.description}
                                                </small>
                                            </span>,
                                            item.quantity,
                                            <span key={index}>
                                                <ButtonGroup>
                                                    <IconButton
                                                        aria-label="decrease quantity"
                                                        onClick={() => decreaseQuantity(index)}
                                                    >
                                                        <RemoveCircle />
                                                    </IconButton>

                                                    <IconButton
                                                        aria-label="increase quantity"
                                                        onClick={() => increaseQuantity(index)}
                                                    >
                                                        <AddCircle />
                                                    </IconButton>
                                                </ButtonGroup>
                                            </span>,
                                            <span key={index}>
                                                <Currency
                                                    quantity={item.price * item.quantity}
                                                    currency="EUR"
                                                    locale="nl_NL"
                                                />
                                            </span>,
                                            <Tooltip
                                                key={index}
                                                id="close"
                                                title="Remove item"
                                                placement="left"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <Button link className={classes.actionButton}>
                                                    <Close onClick={() => removeItem(index)} />
                                                </Button>
                                            </Tooltip>
                                        ])}
                                        tableShopping
                                        customHeadCellClasses={[
                                            classes.description,
                                            classes.textRight,
                                            classes.description,
                                            classes.textRight
                                        ]}
                                        customHeadClassesForCells={[0, 1, 2, 3]}
                                        customCellClasses={[
                                            classes.tdName,
                                            classes.tdNumber,
                                            classes.tdQuantity + " " + classes.tdNumberAndButtonGroup,
                                            classes.tdNumberSmall + " " + classes.textRight
                                        ]}
                                        customClassesForCells={[0, 1, 2, 3]}
                                    />

                                    <div className={classes.textRight}>
                                        <span className={classes.totals}>
                                            <Currency quantity={total} currency="EUR" locale="nl_NL" />
                                        </span>
                                        <Button
                                            color="info"
                                            round
                                            onClick={completeOrder}
                                            disabled={selectedTable === "unknown"}
                                        >
                                            {I18n.t("checkoutPage.orderButton")} <KeyboardArrowRight />
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()}, {shop.complete ? shop.data.name : ""}
                        </div>
                    </div>
                }
            />
        </div>
    );
}
